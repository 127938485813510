import type { EditorSDK } from '@wix/platform-editor-sdk';

import { add as addHistory, HistoryLabel } from '../../../wrappers/history';
import { PUBLIC_API_NOT_FOUND_ERROR, uninstallMembersBlocksPlatform } from './members-blocks-platform';
import { shouldEnableUninstallMASubApps } from '../../../../utils/experiments';

export const uninstallMembersArea = async (editorSDK: EditorSDK) => {
  if (!(await shouldEnableUninstallMASubApps())) {
    try {
      await uninstallMembersBlocksPlatform(editorSDK);
    } catch (e) {
      // No need to throw an error if the app is not installed
      if ((e as Error).message !== PUBLIC_API_NOT_FOUND_ERROR) {
        throw e;
      }
    }
  }

  await editorSDK.application.uninstall('', { openConfirmation: false });
  await addHistory(editorSDK, HistoryLabel.UninstallV3);
};
