import type { EditorSDK } from '@wix/platform-editor-sdk';

import type { MembersAreaPublicApi } from '../types';

import { monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { MembersAreaContext } from '../../../types/context';
import { getAppDefinitionByWidgetId } from '../../services/definitions';
import { shouldEnableExtendedUninstallMASubApps } from '../../../utils/experiments';
import { openAddTabsPanel, openUnifiedManagerPanel } from '../../wrappers/panels';
import enforceSequentiality from '../../enforceSequentiality';
import { getIsResponsiveEditor } from '../../services/applicationState';
import { getDefinitions } from '../../wrappers/definitions';
import { uninstallDeletableApps } from '../../services/uninstall-apps';
import { maybeShowProfileCard } from './services/layout';
import { getProfileType, setProfileType } from './services/blog-writer';
import { modifyPages, modifyPagesAndOpenManagePages } from './services/general-settings';
import { isProfilePageBobValid, navigateToSection } from './services/members-area-page';
import { getRoutes, routeExists, setRoutes } from './services/routes';
import {
  deleteVerticalApps,
  getRegisteredApps,
  installApps,
  installRegisteredApps,
  registerMembersAreaApps,
} from './integration';

const initPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const sequentialMonitoredTransaction = <T>(name: string, action: () => Promise<T>) => {
    return enforceSequentiality(name, () => monitoredTransaction(name, action)) as unknown as Promise<T>;
  };

  return {
    getRegisteredApps: () => {
      return getRegisteredApps(editorSDK);
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      const action = () => registerMembersAreaApps(editorSDK, applications, verticalAppDefId, applicationsOptions);
      return monitoredTransaction('editorAPI.v2.registerMembersAreaApps', action);
    },
    installRegisteredApps: (verticalAppDefId) => {
      const action = () => installRegisteredApps(editorSDK, verticalAppDefId);
      return sequentialMonitoredTransaction('editorAPI.v2.installRegisteredApps', action);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      const action = async () => {
        const definitions = await getDefinitions(editorSDK, applications);
        await installApps(editorSDK, definitions, shouldNavigate);
        return maybeShowProfileCard(editorSDK, definitions);
      };

      return sequentialMonitoredTransaction('editorAPI.v2.addApplications', action);
    },
    handleVerticalDeletion: async (verticalAppDefId) => {
      return monitoredTransaction('editorAPI.v2.handleVerticalDeletion#deleteVerticalApps', () =>
        deleteVerticalApps(editorSDK, verticalAppDefId),
      );
    },
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (profileType) => {
      return monitoredTransaction('editorAPI.v2.setProfileType', () => setProfileType(editorSDK, profileType));
    },
    modifyPages: (options) => {
      return monitoredTransaction('editorAPI.v2.modifyPages', () => modifyPages(editorSDK, options));
    },
    modifyPagesAndOpenManagePages: (options, referralInfo) => {
      return monitoredTransaction('editorAPI.v2.modifyPagesAndOpenManagePages', () =>
        modifyPagesAndOpenManagePages(editorSDK, options, referralInfo),
      );
    },
    refreshPageState: () => {
      return editorSDK.application.reloadManifest();
    },
    _getIsResponsiveEditor: () => {
      return Promise.resolve(getIsResponsiveEditor());
    },
    openAddTabsPanel: ({ referralInfo }) => openAddTabsPanel({ editorSDK, eventPayload: { referralInfo } }),
    getIsMembersAreaV2Context: () => Promise.resolve(true),
    getMembersAreaContext: () => Promise.resolve(MembersAreaContext.V2),
    isAppSectionInstalled: (sectionData) => routeExists(editorSDK, sectionData),
    navigateToSection: ({ widgetId }) =>
      monitoredTransaction('editorAPI.v2.navigateToSection', () => navigateToSection(editorSDK, widgetId)),
    getRoutes: () => {
      const action = async () => getRoutes(editorSDK);
      return toMonitored('editorAPI.v2.getRoutes', action);
    },
    setRoutes: (routes) => {
      const action = () => {
        return setRoutes(editorSDK, routes);
      };
      return monitoredTransaction('editorAPI.v2.setRoutes', action);
    },
    openUnifiedManagerPanel: async (options) => {
      return openUnifiedManagerPanel({ editorSDK, eventPayload: options });
    },
    uninstallSubApp: async ({ widgetId }) => {
      if (await shouldEnableExtendedUninstallMASubApps()) {
        const definition = await getAppDefinitionByWidgetId(editorSDK, widgetId);

        await uninstallDeletableApps(editorSDK, [definition]);
      }
    },
    /** @deprecated */
    openGeneralSettingsPanel: async () => {
      throw new Error('[MAV2] PublicAPI.openGeneralSettingsPanel is not supported');
    },
    /** @deprecated */
    getGeneralSettingsPanelData: () => {
      throw new Error('[MAV2] PublicAPI.getGeneralSettingsPanelData is not supported');
    },
    /** @deprecated */
    getMemberPrivacySettings: () => {
      throw new Error('[MAV2] PublicAPI.getMemberPrivacySettings is not supported');
    },
    /** @deprecated */
    getMembersPageRef: (page) => {
      throw new Error('[MAV2] PublicAPI.getMembersPageRef is not implemented');
    },
    /** @deprecated */
    removeMembersAreaPage: (pageId, appDefinitionId) => {
      throw new Error('[MAV2] PublicAPI.removeMembersAreaPage is not implemented');
    },
    /** @deprecated */
    setHorizontalLayout: (pwHeight) => {
      throw new Error('[MAV2] PublicAPI.setHorizontalLayout is not implemented');
    },
    /** @deprecated */
    setSidebarLayout: () => {
      throw new Error('[MAV2] PublicAPI.setSidebarLayout is not implemented');
    },
    /** @deprecated */
    addCustomPage: (isPrivate) => {
      throw new Error('[MAV2] PublicAPI.addCustomPage is not implemented');
    },
    /** @deprecated */
    refreshMembersAreaApps: () => {
      throw new Error('[MAV2] PublicAPI.refreshMembersAreaApps is not implemented');
    },
    /** @deprecated */
    setProfileWidgetHeight: (height) => {
      throw new Error('[MAV2] PublicAPI.setProfileWidgetHeight is not implemented');
    },
    /** @deprecated */
    refreshRouters: () => {
      throw new Error('[MAV2] PublicAPI.refreshRouters is not implemented');
    },
    /** @deprecated */
    registerAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.registerAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    getAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.getAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    installAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.installAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    getSettingsRoutes: () => {
      throw new Error('[MAV2] PublicAPI.getSettingsRoutes is not implemented');
    },
    setSettingsRoutes: () => {
      throw new Error('[MAV2] PublicAPI.setSettingsRoutes is not implemented');
    },
    openAddProfileTabsPanel: () => {
      throw new Error('[MAV2] PublicAPI.openAddProfileTabsPanel is not supported');
    },
    openAddSettingsTabsPanel: () => {
      throw new Error('[MAV2] PublicAPI.openAddSettingsTabsPanel is not supported');
    },
  };
};

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const getPublicAPI = async () => {
    const publicAPI = initPublicAPI(editorSDK);

    if (!(await isProfilePageBobValid(editorSDK))) {
      throw new Error('[MA Umbrella - Public API V2] Member Page or Widget is missing');
    }

    return publicAPI;
  };

  return {
    getRegisteredApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRegisteredApps();
    },
    registerMembersAreaApps: async (applications, verticalAppDefId, applicationsOptions) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.registerMembersAreaApps(applications, verticalAppDefId, applicationsOptions);
    },
    installRegisteredApps: async (verticalAppDefId, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installRegisteredApps(verticalAppDefId, options);
    },

    addApplications: async (applications, shouldNavigate, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addApplications(applications, shouldNavigate, options);
    },
    handleVerticalDeletion: async (verticalAppDefId) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.handleVerticalDeletion(verticalAppDefId);
    },
    getProfileType: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getProfileType();
    },
    setProfileType: async (profileType) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileType(profileType);
    },
    modifyPages: async (options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPages(options);
    },
    modifyPagesAndOpenManagePages: async (options, referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPagesAndOpenManagePages(options, referralInfo);
    },
    refreshPageState: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshPageState();
    },
    _getIsResponsiveEditor: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI._getIsResponsiveEditor();
    },
    openAddTabsPanel: async (props) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openAddTabsPanel(props);
    },
    getIsMembersAreaV2Context: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getIsMembersAreaV2Context();
    },
    getMembersAreaContext: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaContext();
    },
    isAppSectionInstalled: async (sectionData) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.isAppSectionInstalled(sectionData);
    },
    navigateToSection: async (section) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(section);
    },
    openGeneralSettingsPanel: async (referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openGeneralSettingsPanel(referralInfo);
    },
    getGeneralSettingsPanelData: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getGeneralSettingsPanelData();
    },
    getMemberPrivacySettings: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMemberPrivacySettings();
    },
    getMembersPageRef: async (page) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersPageRef(page);
    },
    removeMembersAreaPage: async (pageId, appDefinitionId) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeMembersAreaPage(pageId, appDefinitionId);
    },
    setHorizontalLayout: async (pwHeight) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setHorizontalLayout(pwHeight);
    },
    setSidebarLayout: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSidebarLayout();
    },
    addCustomPage: async (isPrivate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addCustomPage(isPrivate);
    },
    refreshMembersAreaApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshMembersAreaApps();
    },
    setProfileWidgetHeight: async (height) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileWidgetHeight(height);
    },
    refreshRouters: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshRouters();
    },
    registerAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.registerAdditionalWidgets();
    },
    getAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getAdditionalWidgets();
    },
    installAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installAdditionalWidgets();
    },
    getRoutes: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.getRoutes();
    },
    setRoutes: async (routes) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.setRoutes(routes);
    },
    getSettingsRoutes: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.getSettingsRoutes();
    },
    setSettingsRoutes: async (routes) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.setSettingsRoutes(routes);
    },
    openUnifiedManagerPanel: async (options) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.openUnifiedManagerPanel(options);
    },
    uninstallSubApp: async (props) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.uninstallSubApp(props);
    },
  };
};
