import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-integration-kit';

import { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';
import { retryPromise } from '../../../../utils/promises';
import { getById as getComponentById, getComponentPage } from '../../../wrappers/components';
import { remove as removePage } from '../../../wrappers/pages';
import { getPageByIntegrationApp, updatePageWithManagingAppDefId } from '../../../services/pages';
import { removeApplicationsFromSlots } from '../services/members-area-page';
import { groupDefinitionsByMethod } from './definitions';
import { registerAlwaysAvailableApps } from './register-apps';
import { getAppsToDeleteWithVertical } from './get-apps';
import { uninstallDeletableApps } from '../../../services/uninstall-apps';
import { shouldEnableExtendedUninstallMASubApps } from '../../../../utils/experiments';

const getPageHasManagingAppDefId = async (editorSDK: EditorSDK, definition: IntegrationApplicationWithoutWidgetId) => {
  const page = await getPageByIntegrationApp({ editorSDK, app: definition });

  return !!page?.managingAppDefId;
};

const deleteSitePage = async (editorSDK: EditorSDK, definition: IntegrationApplicationWithoutWidgetId) => {
  const page = await getPageByIntegrationApp({ editorSDK, app: definition });
  if (!page) {
    return;
  }

  const componentRef = await getComponentById({ editorSDK, id: page.id! });
  if (!componentRef) {
    return;
  }

  const pageRef = await getComponentPage(editorSDK, componentRef);
  if (!pageRef) {
    return;
  }

  if (!page.managingAppDefId) {
    const retryOptions = {
      delayMs: 100,
      delayConditionCheckMs: 100,
      message: `Failed to to update page with managing app def id ${definition.appDefinitionId}`,
      maxTries: 4,
      condition: () => getPageHasManagingAppDefId(editorSDK, definition),
    };

    await retryPromise(() => updatePageWithManagingAppDefId({ editorSDK, pageRef }), retryOptions);
  }

  await removePage({ editorSDK, pageRef, shouldShowEditorRemovePanel: false });
};

const deleteSitePages = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithoutWidgetId[]) => {
  await Promise.all(definitions.map((definition) => deleteSitePage(editorSDK, definition)));
};

export const deleteApps = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
) => {
  const { widgetPlugins, siteApps } = groupDefinitionsByMethod(definitions);

  if (widgetPlugins.length > 0) {
    await removeApplicationsFromSlots(editorSDK, widgetPlugins);
  }

  if (siteApps.length > 0) {
    await deleteSitePages(editorSDK, siteApps);
  }

  if (await shouldEnableExtendedUninstallMASubApps()) {
    await uninstallDeletableApps(editorSDK, definitions);
  }

  await registerAlwaysAvailableApps(editorSDK);
};

export const deleteVerticalApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const appsToDeleteWithVertical = await getAppsToDeleteWithVertical(editorSDK, verticalAppDefId);
  return deleteApps(editorSDK, appsToDeleteWithVertical);
};
