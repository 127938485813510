import { EditorSDK } from '@wix/platform-editor-sdk';
import { createInstance } from 'i18next';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { getAppDefinition } from '@wix/members-area-app-definitions/get-app-definitions';

import { MENU_IDS } from '../../../constants';
import { getInstalledApplications } from './members-area-page';
import { addIconsMenuItems } from './menu-items';
import { getMenuItems } from '../../../wrappers/menus';
import { log } from '../../../../utils/monitoring';

export const maybeAddNotificationsIcon = async (editorSDK: EditorSDK) => {
  try {
    const notificationsAppDefinition = await getAppDefinition({
      editorSDK,
      application: MA_APP_IDS.NOTIFICATIONS,
      // @ts-expect-error - versions mismatch
      i18next: createInstance(),
    });
    const iconsMenuItems = await getMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID });
    const installedApps = await getInstalledApplications(editorSDK);
    const notificationsApp = installedApps.find((app) => app.widgetId === notificationsAppDefinition.widgetId);

    if (notificationsApp && iconsMenuItems.length === 0) {
      await addIconsMenuItems(editorSDK, [notificationsAppDefinition]);
    }
  } catch (e) {
    log('Datafixer failed - failed to add notifications icon bell', {
      extra: {
        error: e,
      },
    });
  }
};
