import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { IntegrationApplication, MembersAreaAppsOptions } from '@wix/members-area-app-definitions';
import { MA_APP_IDS, WidgetId } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/get-app-definitions';
import { createInstance } from 'i18next';

import { shouldEnableFollowersAsLightbox } from '../../../../utils/experiments';
import { getUniqueApplications } from '../../../../utils/pages';
import {
  ALWAYS_REGISTERED_APPS,
  ALWAYS_REGISTERED_SOCIAL_MA_APPS,
  ALWAYS_REGISTERED_SOCIAL_MA_APPS_NEW,
  PROFILE_PAGE_BOB_APP_DEF_ID,
} from '../../../constants';
import { isMemberProfilePageInstalled } from '../../../wrappers/tpa';
import { isApplicationInstalled } from '../../../wrappers/application';
import { getAllIntegratedApps, getIsADI, setIntegratedApps } from '../../../services/applicationState';
import { maybeResetProfileRolesOnVerticalRegistration } from '../services/blog-writer';

const isFollowingFollowersInstalled = (editorSDK: EditorSDK) => {
  return isApplicationInstalled(editorSDK, MA_APP_IDS.FOLLOWERS.appDefinitionId);
};

const replaceFFPageWithLightbox = async (editorSDK: EditorSDK, applications: IntegrationApplication[]) => {
  if (await isFollowingFollowersInstalled(editorSDK)) {
    return applications;
  }

  return applications.map((app) => {
    if (app.widgetId === WidgetId.FollowingFollowers) {
      return MA_APP_IDS.FOLLOWERS_LIGHTBOX;
    }

    return app;
  });
};

export const registerMembersAreaApps = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  verticalAppDefId: string,
  applicationsOptions: MembersAreaAppsOptions,
) => {
  const isFollowersAsLightboxEnabled = await shouldEnableFollowersAsLightbox();

  const applicationsToRegister = isFollowersAsLightboxEnabled
    ? await replaceFFPageWithLightbox(editorSDK, applications)
    : applications;

  const applicationDefinitions = await getAppDefinitions({
    applications: applicationsToRegister,
    editorSDK,
    applicationsOptions,
    // @ts-expect-error - versions mismatch
    i18next: createInstance(),
  });

  const currentIntegratedAppsMap = getAllIntegratedApps();

  const verticalsApps = getUniqueApplications([
    ...(currentIntegratedAppsMap[verticalAppDefId] ?? []),
    ...applicationDefinitions,
  ]);

  setIntegratedApps({
    ...currentIntegratedAppsMap,
    [verticalAppDefId]: verticalsApps,
  });

  await maybeResetProfileRolesOnVerticalRegistration(editorSDK, verticalAppDefId, verticalsApps);
};

const getAlwaysAvailableSocialApplications = async (editorSDK: EditorSDK) => {
  const isFollowersAsLightboxEnabled = await shouldEnableFollowersAsLightbox();

  if (isFollowersAsLightboxEnabled) {
    const isFFAppInstalled = await isFollowingFollowersInstalled(editorSDK);
    return isFFAppInstalled ? ALWAYS_REGISTERED_SOCIAL_MA_APPS : ALWAYS_REGISTERED_SOCIAL_MA_APPS_NEW;
  }

  return ALWAYS_REGISTERED_SOCIAL_MA_APPS;
};

export const registerAlwaysAvailableApps = async (editorSDK: EditorSDK) => {
  if (getIsADI()) {
    return;
  }

  const isSocialMA = await isMemberProfilePageInstalled(editorSDK);

  const alwaysAvailableApps = isSocialMA
    ? ALWAYS_REGISTERED_APPS.concat(await getAlwaysAvailableSocialApplications(editorSDK))
    : ALWAYS_REGISTERED_APPS;

  const alwaysAvailableAppsDefinitions = await getAppDefinitions({
    applications: alwaysAvailableApps,
    editorSDK,
    // @ts-expect-error - versions mismatch
    i18next: createInstance(),
  });

  setIntegratedApps({
    ...getAllIntegratedApps(),
    [PROFILE_PAGE_BOB_APP_DEF_ID]: alwaysAvailableAppsDefinitions,
  });
};
