import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';
import type { IntegrationApplication } from '@wix/members-area-app-definitions';
import { InstallLocation } from '@wix/members-area-app-definitions';
import type { IntegrationApplicationMap } from '@wix/members-area-integration-kit';
import { MEMBERS_AREA_V2, MEMBERS_PROFILE_CARD } from '@wix/app-definition-ids';
import { getAppDefinition } from '@wix/members-area-app-definitions/get-app-definitions';

import type { IntegrationApplicationWithoutWidgetId } from '../../types/general-settings';

import { getAllIntegratedApps } from './applicationState';
import { asyncFilter } from '../../utils/promises';
import { isAppInstalled } from './integration';

const uninstallApplicationsWrapper = async (editorSDK: EditorSDK, appDefIdsToUninstall: string[]) => {
  const uninstallAppsPromises = appDefIdsToUninstall.map((appDefinitionId) =>
    // @ts-expect-error
    editorSDK.application.uninstall('', { appDefinitionId, openConfirmation: false }),
  );

  return Promise.all(uninstallAppsPromises);
};

const getAppDefIdsToUninstall = (
  definitionsToUninstall: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
) => {
  return definitionsToUninstall.reduce<string[]>((acc, app) => {
    if (app.uninstallable && !acc.includes(app.appDefinitionId)) {
      acc.push(app.appDefinitionId);
    }
    return acc;
  }, []);
};

export const uninstallDeletableApps = async (
  editorSDK: EditorSDK,
  definitionsToUninstall: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
) => {
  const appDefIdsToUninstall = getAppDefIdsToUninstall(definitionsToUninstall);
  return uninstallApplicationsWrapper(editorSDK, appDefIdsToUninstall);
};

const getUninstallableAppDefIdsFromProfilePage = async (editorSDK: EditorSDK) => {
  const allIntegratedApps: IntegrationApplicationMap = getAllIntegratedApps();
  const apps = Object.values(allIntegratedApps).flat() as IntegrationApplication[];
  const profilePageApps = apps.filter((app) => app.installLocation === InstallLocation.Profile);

  const appDefIdsToDeleteFromDefinitions = getAppDefIdsToUninstall(profilePageApps);
  const appDefIdsToDelete = [...appDefIdsToDeleteFromDefinitions, MEMBERS_PROFILE_CARD];
  const installedAppDefIdsToDelete: string[] = await asyncFilter(appDefIdsToDelete, isAppInstalled(editorSDK));

  return installedAppDefIdsToDelete;
};

export const uninstallAppOnPageDelete = async (editorSDK: EditorSDK, pageData: PageData) => {
  if (pageData.appDefinitionId === MEMBERS_AREA_V2) {
    const appDefIdsToUninstall = await getUninstallableAppDefIdsFromProfilePage(editorSDK);
    return uninstallApplicationsWrapper(editorSDK, appDefIdsToUninstall);
  }

  if (pageData.tpaPageId && pageData.appDefinitionId) {
    const appDefinition = await getAppDefinition({
      editorSDK,
      application: { pageId: pageData.tpaPageId, appDefinitionId: pageData.appDefinitionId },
    });

    await uninstallDeletableApps(editorSDK, [appDefinition]);
  }
};

const getAppDefIdsToUninstallOnMaUninstall = async (editorSDK: EditorSDK) => {
  const allIntegratedApps: IntegrationApplicationMap = getAllIntegratedApps();
  const apps = Object.values(allIntegratedApps).flat() as IntegrationApplication[];

  const appDefIdsToDeleteFromDefinitions = getAppDefIdsToUninstall(apps);
  const appDefIdsToDelete = [...appDefIdsToDeleteFromDefinitions, MEMBERS_PROFILE_CARD, MEMBERS_AREA_V2];
  const installedAppDefIdsToDelete: string[] = await asyncFilter(appDefIdsToDelete, isAppInstalled(editorSDK));

  return installedAppDefIdsToDelete;
};

export const uninstallAppsOnMaUninstall = async (editorSDK: EditorSDK) => {
  const appDefIdsToUninstall = await getAppDefIdsToUninstallOnMaUninstall(editorSDK);
  return uninstallApplicationsWrapper(editorSDK, appDefIdsToUninstall);
};
