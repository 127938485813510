import { MEMBERS_ABOUT } from '@wix/app-definition-ids';
import { ComponentRef, ContextParams, DynamicPageLink, EditorSDK, MenuItem, TPAPageId } from '@wix/platform-editor-sdk';
import { getPageIdFromWidgetId, IntegrationApplication, MA_APP_IDS, WidgetId } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/get-app-definitions';
import { createInstance } from 'i18next';
import eventually from 'wix-eventually';

import {
  ALL_MEMBERS_APP_DEF_ID,
  APP_TOKEN,
  MEMBER_TPA_PAGE_ID,
  MENU_IDS,
  MY_ACCOUNT_APP_DEF_ID,
  PROFILE_WIDGET_APP,
} from '../constants';
import { getMembersAreaRouters } from './routers';
import { installApps } from '../platform-app/ma-on-msb/integration';
import { setIsMigration } from './applicationState';
import { getMembersAreaPagePublicAPI } from '../platform-app/ma-on-msb/services/members-area-page';
import { getMembersAreaMenus, getMenuItems, removeItemsFromMenus } from '../wrappers/menus';
import { getAll as getAllRouters, removeAllRouters, removeConnectedPages } from '../wrappers/routers';
import { remove as removePagesGroup } from '../wrappers/pagesGroup';
import { getAllMasterControllers } from '../wrappers/controllers';
import {
  getComponentData,
  getComponentsDataByType,
  getComponentType,
  removeComponentsByAppDefIds,
  updateComponentLayout,
} from '../wrappers/components';
import {
  getMemberProfilePageRef,
  getStyleParams,
  getTpaData,
  parseStyleParamsForSetter,
  parseTpaDataParamsForSetter,
} from '../wrappers/tpa';
import {
  monitoredAddGlobalController,
  monitoredAddMembersAreaPage,
  monitoredAddMyAccountMenuItemToMembersAreaMenus,
} from '../platform-app/ma-on-msb/editor-ready-utils/monitored-methods';
import { maybeDeleteSOSPContainer } from '../membersLogic';
import { getAllPages, updatePageData } from '../wrappers/pages';

import { migrateMenus } from './v2-migration-menus';
import { navigateToMembersAreaPage } from '../platform-app/ma-on-msb/services/navigation';
import { initMemberMenuComponentMigration } from './v2-migration-member-menu-components';

type AllApplicationSettings = Awaited<ReturnType<typeof getApplicationSettings>>;

const PROFILE_CARD_APP = { appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId, widgetId: WidgetId.ProfileCard };
const MEMBER_PAGE_SECTION_HEIGHT = 776;

const getWidgetRef = async (editorSDK: EditorSDK, widgetId: string, componentType: string) => {
  const componentsRefs = await getComponentsDataByType(editorSDK, componentType);
  const [widgetRefComp] = componentsRefs.filter((refComp) => {
    const data = refComp?.data as { widgetId: string };
    return data?.widgetId === widgetId;
  });

  return widgetRefComp?.componentRef ?? null;
};

export const getProfilePageBobWidgetRef = async (editorSDK: EditorSDK) => {
  const ref = await getWidgetRef(editorSDK, WidgetId.MemberPage, 'wysiwyg.viewer.components.RefComponent');

  if (!ref) {
    throw new Error('Member page is missing during migration');
  }

  return ref;
};

const getProfileCardPublicApi = (editorSDK: EditorSDK) =>
  editorSDK.application.getPublicAPI('', {
    appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId,
  }) as Promise<any>;

const getMemberPageSectionRef = async (editorSDK: EditorSDK) => {
  const memberPageWidgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const [sectionRef] = await editorSDK.components.getAncestors('', { componentRef: memberPageWidgetRef });
  return sectionRef;
};

async function getIsLoginBarCompRef(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const componentType = await getComponentType({ editorSDK, componentRef });
  return componentType === 'wysiwyg.viewer.components.LoginSocialBar';
}

async function getIsLoginBarController(editorSDK: EditorSDK, controllerRef: ComponentRef) {
  // @ts-expect-error - bad returned value type
  const { controllerType } = await getComponentData({
    editorSDK,
    componentRef: controllerRef,
  });

  return controllerType === 'members-login-bar';
}

async function removeComponents(editorSDK: EditorSDK, controllerRef: { controllerRef: ComponentRef }) {
  const componentsRefs = await editorSDK.controllers.listConnectedComponents(APP_TOKEN, controllerRef);
  for (const componentRef of componentsRefs) {
    if (await getIsLoginBarCompRef(editorSDK, componentRef)) {
      continue;
    }

    await editorSDK.components.remove(APP_TOKEN, { componentRef });
  }
}

async function removeController(editorSDK: EditorSDK, { controllerRef }: { controllerRef: ComponentRef }) {
  if (await getIsLoginBarController(editorSDK, controllerRef)) {
    return;
  }

  await editorSDK.components.remove(APP_TOKEN, { componentRef: controllerRef });
}

async function clearMenuItems(editorSDK: EditorSDK) {
  const maMenus = await getMembersAreaMenus(editorSDK);
  return removeItemsFromMenus(editorSDK, maMenus);
}

async function wipeOut(editorSDK: EditorSDK) {
  const controllersRefs = await getAllMasterControllers(editorSDK);

  for (const controllerRef of controllersRefs) {
    await removeComponents(editorSDK, controllerRef);
    await removeController(editorSDK, controllerRef);
  }

  const appDefIdsToRemove = [PROFILE_WIDGET_APP.appDefinitionId, ALL_MEMBERS_APP_DEF_ID];
  await removeComponentsByAppDefIds(editorSDK, appDefIdsToRemove);
}

const getAlreadyInstalledApplications = async (editorSDK: EditorSDK) => {
  // TO DO: handle unexpected states of routers
  const routers = await getMembersAreaRouters(editorSDK);
  // @ts-expect-error - "patterns" type is invalid
  const privatePages = Object.values(routers.privateRouter.config.patterns).map((p) => p.appData);
  // @ts-expect-error - "patterns" type is invalid
  const publicPages = Object.values(routers.publicRouter.config.patterns || {}).map((p) => p.appData);

  const allPages = [...privatePages, ...publicPages];
  const appDefinitionsToInstall = allPages.map(({ appDefinitionId, appPageId }) => ({
    appDefinitionId,
    pageId: appPageId,
  }));

  const integratedAppsDefinitions = await getAppDefinitions({
    applications: appDefinitionsToInstall.filter((app) => app.appDefinitionId !== MY_ACCOUNT_APP_DEF_ID),
    editorSDK,
    // @ts-expect-error - versions mismatch
    i18next: createInstance(),
  });

  const appsWithMenuItems = await filterAppsWithMenuItems(editorSDK, integratedAppsDefinitions);

  return [MA_APP_IDS.MY_ACCOUNT, ...appsWithMenuItems] as IntegrationApplication[];
};

async function clearMembersAreaV1(editorSDK: EditorSDK) {
  await maybeDeleteSOSPContainer(editorSDK);
  await removePagesGroup(editorSDK);
  await removeConnectedPages(editorSDK);
  await removeAllRouters(editorSDK);
  await wipeOut(editorSDK);
  await clearMenuItems(editorSDK);
}

// Sled + Editor has an issue where the page does not appear instantly after the promise resolves
async function confirmMembersAreaPage(editorSDK: EditorSDK) {
  const allPages = await getAllPages({ editorSDK });
  const memberPage = allPages.find((page) => page.tpaPageId === MEMBER_TPA_PAGE_ID);

  if (!memberPage) {
    throw new Error('Member Page missing after its installation');
  }
}

async function installMembersAreaV2(editorSDK: EditorSDK, options: ContextParams) {
  await monitoredAddGlobalController(editorSDK);
  await monitoredAddMembersAreaPage(editorSDK);
  await eventually(() => confirmMembersAreaPage(editorSDK), { timeout: 5000, interval: 1000 });
  await monitoredAddMyAccountMenuItemToMembersAreaMenus(editorSDK, options);
}

async function installVerticalsApplications(editorSDK: EditorSDK, appDefinitions: IntegrationApplication[]) {
  await installApps(editorSDK, appDefinitions);
  const membersAreaPagePublicApi = await getMembersAreaPagePublicAPI(editorSDK);
  await membersAreaPagePublicApi.refreshApp();
}

const alignSectionSize = async (editorSDK: EditorSDK) => {
  const sectionRef = await getMemberPageSectionRef(editorSDK);
  await updateComponentLayout({ editorSDK, componentRef: sectionRef, layout: { height: MEMBER_PAGE_SECTION_HEIGHT } });
};

async function getWidgetRefInGivenMA(
  editorSDK: EditorSDK,
  { widgetId, appDefinitionId }: { widgetId: string; appDefinitionId: string },
  maVersion: 'v1' | 'v2',
) {
  const v2Page = maVersion === 'v2' ? await getMemberProfilePageRef(editorSDK) : null;
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', appDefinitionId);
  const appsWidgets = await editorSDK.tpa.app.getAllCompsByApplicationId('', appData.applicationId);
  const widgetComps = appsWidgets.filter((widget) => widget.widgetId === widgetId);
  const widget = widgetComps.find((w) => (maVersion === 'v2' ? w.pageId === v2Page?.id : w.pageId !== v2Page?.id));

  if (!widget) {
    throw new Error(`Failed to find widget ${widgetId} in MA ${maVersion}`);
  }

  return editorSDK.components.getById('', { id: widget.id });
}

async function getApplicationSettings(editorSDK: EditorSDK, ref: ComponentRef) {
  try {
    const [styleParams, tpaData] = await Promise.all([getStyleParams(editorSDK, ref), getTpaData(editorSDK, ref)]);
    return { styleParams, tpaData };
  } catch (e) {
    console.error(
      'Failed to copy application settings during MA migration - probably because permissions restrictions',
    );
    throw e;
  }
}

async function setApplicationSettings(editorSDK: EditorSDK, compRef: ComponentRef, settings: AllApplicationSettings) {
  // We have no permissions to set other TPAs styleParams and tpa data
  // Need deploy preview of this PR for it to work https://github.com/wix-private/editor-platform/pull/6354
  // Styles works unexpectedly though - not secured like tpa.data permissions
  try {
    const mappedParams = parseStyleParamsForSetter(settings.styleParams);
    await editorSDK.tpa.setStyleParams('', { compRef, styleParams: mappedParams });
  } catch (e) {
    console.log('Failed to copy some widgets style params while migrating Members Area');
  }

  // We have no permissions to set other TPAs datas which is an issue
  // This code would do it, now it will print the errors
  try {
    const componentDataParams = settings.tpaData.COMPONENT || {};
    const mappedDatas = parseTpaDataParamsForSetter(componentDataParams, compRef);
    await Promise.all(mappedDatas.map((data) => editorSDK.tpa.data.set('', data)));
  } catch (e) {
    console.log('Failed to copy some widgets data params while migrating Members Area');
  }
}

export const maybeAddProfileCard = async (
  editorSDK: EditorSDK,
  alreadyInstalledApplications: IntegrationApplication[],
  profileCardSettings: AllApplicationSettings,
  wasHorizontalLayout: boolean,
) => {
  const profileCard = alreadyInstalledApplications.find((app) => app.appDefinitionId === MEMBERS_ABOUT);

  if (profileCard) {
    const membersAPI = await getMembersAreaPagePublicAPI(editorSDK);
    await membersAPI.showProfileCard();
    const newProfileCardRef = await getWidgetRefInGivenMA(editorSDK, PROFILE_CARD_APP, 'v2');
    await setApplicationSettings(editorSDK, newProfileCardRef, profileCardSettings);
  }

  if (wasHorizontalLayout) {
    const memberPageWidgetRef = await getProfilePageBobWidgetRef(editorSDK);
    await editorSDK.components.setFullWidth('', {
      componentRef: memberPageWidgetRef,
      fullWidth: true,
    });
  }
};

export const maybeSetVerticalLayout = async (editorSDK: EditorSDK, wasHorizontalLayout: boolean) => {
  if (wasHorizontalLayout) {
    return;
  }

  const membersAPI = await getMembersAreaPagePublicAPI(editorSDK);
  await membersAPI.setSidebarLayout();
};

type SectionBackground = {
  colorOpacity: number;
  color: string;
};

const getMyAccountSectionBackground = async (editorSDK: EditorSDK) => {
  const myAccountWidgetRef = await getWidgetRefInGivenMA(editorSDK, MA_APP_IDS.MY_ACCOUNT, 'v1');
  const [myAccountSectionRef] = await editorSDK.components.getAncestors('', { componentRef: myAccountWidgetRef });
  const { background } = await editorSDK.components.design.get('', { componentRef: myAccountSectionRef });
  return background as SectionBackground;
};

const alignSectionBackground = async (editorSDK: EditorSDK, previousBackground: SectionBackground) => {
  const memberPageSectionRef = await getMemberPageSectionRef(editorSDK);
  await editorSDK.components.design.update('', {
    componentRef: memberPageSectionRef,
    design: { background: { ...previousBackground } },
  });

  const wasSectionOpaque = previousBackground.colorOpacity === 0;

  if (!wasSectionOpaque) {
    return;
  }

  if (previousBackground.color.startsWith('color_')) {
    let newBackgroundColor = previousBackground.color;

    const homePageRef = await editorSDK.pages.getHomePage('');
    const homePageData: any = await editorSDK.components.data.get('', { componentRef: homePageRef });
    const homePageBackground = homePageData.pageBackgrounds?.desktop;

    if (homePageBackground?.ref?.color?.startsWith('{color_')) {
      newBackgroundColor = homePageBackground.ref.color.replace('{', '').replace('}', '');
    }

    const memberPageWidgetRef = await getProfilePageBobWidgetRef(editorSDK);
    const [containerRef] = await editorSDK.components.getChildren('', { componentRef: memberPageWidgetRef });
    const [connectionRef] = await editorSDK.components.getChildren('', { componentRef: containerRef });
    const [backgroundRef] = await editorSDK.components.getChildren('', { componentRef: connectionRef });
    const currentStyle = await editorSDK.components.style.get('', { componentRef: backgroundRef });

    const newStyleProperties = {
      ...currentStyle.style.properties,
      'alpha-bg': '1',
      bg: newBackgroundColor,
    };

    await editorSDK.document.components.style.updateFull('', {
      componentRef: backgroundRef,
      style: {
        ...currentStyle,
        style: {
          ...currentStyle.style,
          properties: newStyleProperties,
        },
      },
    });
  }
};

const shouldPasswordRestrictMemberPage = async (editorSDK: EditorSDK) => {
  const allPages = await editorSDK.pages.data.getAll('');
  const profilePage = allPages.find((p) => p.tpaPageId === getPageIdFromWidgetId(WidgetId.About));

  return profilePage?.pageSecurity.requireLogin;
};

const restrictMemberPageWithPassword = async (editorSDK: EditorSDK) => {
  const memberPageRef = await getMemberProfilePageRef(editorSDK);
  await updatePageData({
    editorSDK,
    pageRef: memberPageRef,
    pageData: { pageSecurity: { requireLogin: true } },
  });
};

const getIntegratedApplicationsSettings = (editorSDK: EditorSDK, applications: IntegrationApplication[]) => {
  return Promise.all(
    applications.map(async (app) => {
      const appDefinition = { appDefinitionId: app.appDefinitionId, widgetId: app.widgetId };
      const appRef = await getWidgetRefInGivenMA(editorSDK, appDefinition, 'v1');
      return getApplicationSettings(editorSDK, appRef);
    }),
  );
};

const setInstalledApplicationsSettings = (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  applicationsSettings: AllApplicationSettings[],
) => {
  return Promise.all(
    applications.map(async (app, index) => {
      const appDefinition = { appDefinitionId: app.appDefinitionId, widgetId: app.widgetId };
      const appRef = await getWidgetRefInGivenMA(editorSDK, appDefinition, 'v2');
      return setApplicationSettings(editorSDK, appRef, applicationsSettings[index]);
    }),
  );
};

const filterAppsWithMenuItems = async (editorSDK: EditorSDK, integratedApplications: IntegrationApplication[]) => {
  const [{ publicRouter, privateRouter }, membersMenuItems, loginMenuItems, iconsMenuItems] = await Promise.all([
    getMembersAreaRouters(editorSDK),
    getMenuItems({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID }),
    getMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID }),
    getMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID }),
  ]);

  const menuItemsContainsPage = (menuItems: MenuItem[], pageId: TPAPageId) => {
    return Boolean(
      menuItems.find((item) => {
        const itemLink = item.link as DynamicPageLink;
        const privateRouterPatterns = privateRouter?.config?.patterns || {};
        const publicRouterPatterns = publicRouter?.config?.patterns || {};
        const menuItemInRouter =
          privateRouterPatterns['/' + itemLink.innerRoute] || publicRouterPatterns['/' + itemLink.innerRoute];
        return menuItemInRouter.appData.appPageId === pageId;
      }),
    );
  };

  return integratedApplications.filter((app) => {
    if (app.appDefinitionId === MA_APP_IDS.FOLLOWERS.appDefinitionId) {
      return true;
    }

    return (
      menuItemsContainsPage(membersMenuItems, app.pageId) ||
      menuItemsContainsPage(loginMenuItems, app.pageId) ||
      menuItemsContainsPage(iconsMenuItems, app.pageId)
    );
  });
};

export const migrateToV2OnTemplates = async (editorSDK: EditorSDK, options: ContextParams): Promise<void> => {
  try {
    setIsMigration(true);
    console.log('Members Area: Starting migration to V2');

    // 1. Save settings for copying
    const profileCardPublicAPI = await getProfileCardPublicApi(editorSDK);
    const installedApplications = await getAlreadyInstalledApplications(editorSDK);
    const integratedApplications = installedApplications.filter((app) => app.appDefinitionId !== MY_ACCOUNT_APP_DEF_ID);
    const shouldPasswordProtectPage = await shouldPasswordRestrictMemberPage(editorSDK);
    const profileCardRef = await getWidgetRefInGivenMA(editorSDK, PROFILE_CARD_APP, 'v1');
    const profileCardSettings = await getApplicationSettings(editorSDK, profileCardRef);
    const wasHorizontalLayout = await profileCardPublicAPI.isHorizontalLayout();
    const oldRouters = await getAllRouters(editorSDK);
    const oldMenus = await getMembersAreaMenus(editorSDK);
    const oldApplicationsSettings = await getIntegratedApplicationsSettings(editorSDK, installedApplications);
    const sectionBackground = await getMyAccountSectionBackground(editorSDK);

    const migrateMemberMenuComponent = await initMemberMenuComponentMigration(editorSDK, wasHorizontalLayout);

    // 2. Clean V1 MA
    await clearMembersAreaV1(editorSDK);

    // 3. Run V2 editorReady with installation
    await installMembersAreaV2(editorSDK, options);

    // 4. Install default integrations applications
    await installVerticalsApplications(editorSDK, integratedApplications);
    await maybeAddProfileCard(editorSDK, installedApplications, profileCardSettings, wasHorizontalLayout);

    // 5. Apply stylings and configurations
    await setInstalledApplicationsSettings(editorSDK, installedApplications, oldApplicationsSettings);
    await migrateMenus({ editorSDK, oldRouters, oldMenus });
    // To do: fix Profile card which does not rerender even with correct styleParam
    await maybeSetVerticalLayout(editorSDK, wasHorizontalLayout);
    // Some updates does not work when you're not in the page, e.g. section height
    await navigateToMembersAreaPage(editorSDK);
    await alignSectionSize(editorSDK);
    await alignSectionBackground(editorSDK, sectionBackground);
    await migrateMemberMenuComponent();

    if (shouldPasswordProtectPage) {
      await restrictMemberPageWithPassword(editorSDK);
    }

    console.log('Members Area: Migration to V2 finished');
    setIsMigration(false);
  } catch (e) {
    throw e;
  }
};
