import type { AppInstancePayload } from '@wix/platform-editor-sdk';
import type { ExperimentsProps } from '@wix/wix-experiments';
import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;
let conductedV3Experiments: Experiments;

// Since we conduct without yoshi, we should still call petri in the same way they do
// Aligned to https://github.com/wix-private/yoshi/blob/master/packages/yoshi-flow-editor-runtime/src/experiments/createExperimentsFromEssentials.ts#L17
function toRequestContext({
  siteOwnerId,
  metaSiteId,
  uid: loggedInUserId,
}: AppInstancePayload): ExperimentsProps['requestContext'] {
  return {
    forSiteOwner: {
      loggedInUserId,
      siteOwnerId,
    },
    overrideCriteria: {
      entityId: metaSiteId,
    },
  };
}

async function initExperiments(appInstance: AppInstancePayload) {
  conductedExperiments = new Experiments({
    scope: 'members-area',
    requestContext: toRequestContext(appInstance),
    useNewApi: true,
  });
  await waitForConduction();
}

async function waitForConduction() {
  if (conductedExperiments) {
    await conductedExperiments.ready();
  }
}

async function initV3Experiments(appInstance: AppInstancePayload) {
  conductedV3Experiments = new Experiments({
    scope: 'members-area-v3',
    requestContext: toRequestContext(appInstance),
    useNewApi: true,
  });
  await waitForV3Conduction();
}

async function waitForV3Conduction() {
  if (conductedV3Experiments) {
    await conductedV3Experiments.ready();
  }
}

async function shouldEnableTimeoutLogs() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.enableTimeoutLogs') === 'true';
}

async function shouldEnableSeoPanelChanges() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.seoPanelChanges') === 'true';
}

async function shouldEnableMigration() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.migrateToV2') === 'true';
}

async function shouldInstallMembersAreaV2() {
  const experimentsV2 = new Experiments({ scope: 'members-area-v2' });
  await experimentsV2.conduct('specs.membersAreaV2.BoBInstallation', 'false');
  return experimentsV2.get('specs.membersAreaV2.BoBInstallation') === 'true';
}

async function shouldInstallMembersAreaV3() {
  await waitForV3Conduction();
  return conductedV3Experiments.get('specs.membersArea.SplitInstallation') === 'true';
}

// TODO: Enable this FT only after specs.membersAreaV2.BoBInstallation is merged.
async function shouldEnableMembersAreaContextCheck() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.EnableMembersAreaContextCheck') === 'true';
}

async function shouldEnableV2SilentInstall() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.EnableV2SilentInstall') === 'true';
}

async function shouldNotWaitNavigation() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.DoNotWaitInstallNavigation') === 'true';
}

async function shouldRemoveUpdatingBoBControllerAndNavigation() {
  try {
    await waitForConduction();
  } catch (e) {}

  return conductedExperiments.get('specs.responsive-editor.NoMeasureInstall') === 'true';
}

async function shouldEnableNotificationsIconFixer() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.NotificationsIconFixerOnV2') === 'true';
}

async function shouldAddNotificationsIcon() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.AddNotificationsIconOnV2') === 'true';
}

async function shouldUseCustomizeMemberPageCTA() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.UseCustomizeMemberPageCTA') === 'true';
}

async function shouldEnableMenusDataFixer() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.EnableMenusDataFixer') === 'true';
}

async function shouldEnableV2TpaPageLinksDataFixer() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.EnableTpaPageLinksDataFixerForV2MenuItems') === 'true';
}

async function shouldEnableV3TpaPageLinksDataFixer() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.EnableTpaPageLinksDataFixerForV3MenuItems') === 'true';
}

async function shouldShowPageRedirectNote() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.ShowPageRedirectNote') === 'true';
}

async function shouldEnableV2PageInfoPanelCustomPage() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersAreaV2.EnablePageInfoPanelCustomPage') === 'true';
}

async function shouldEnableV3ReAddPageWorkaround() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersAreaV3.ReAddPageWorkaround') === 'true';
}

async function shouldEnableMyAccountParallelInstall() {
  try {
    await waitForConduction();
  } catch (e) {}
  return conductedExperiments.get('specs.membersArea.EnableMyAccountParallelInstall') === 'true';
}

async function shouldEnableFollowersAsLightbox() {
  try {
    await waitForConduction();
  } catch (e) {}
  return conductedExperiments.get('specs.membersArea.EnableFollowersAsLightbox') === 'true';
}

async function shouldShowOnboardingModal() {
  await waitForV3Conduction();
  return conductedV3Experiments.get('specs.membersArea.ShowOnboardingModal') === 'true';
}

async function shouldEnableUnifiedManager() {
  await waitForV3Conduction();
  return conductedV3Experiments.get('specs.membersArea.EnableUnifiedManager') === 'true';
}

async function shouldUseNewLoginBarDeleteModal() {
  await waitForV3Conduction();
  return conductedV3Experiments.get('specs.membersArea.UseNewLoginBarDeleteModal') === 'true';
}

async function shouldEnableUninstallMASubApps() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.UninstallMASubApps') === 'true';
}

async function shouldEnableVerticalProfileInstallFixer() {
  await waitForConduction();
  return conductedExperiments.get('specs.membersArea.EnableVerticalProfileInstallFixer') === 'true';
}

async function shouldEnableExtendedUninstallMASubApps() {
  await waitForConduction();
  return conductedExperiments?.get('specs.membersArea.ExtendedUninstallMASubApps') === 'true';
}

export {
  initV3Experiments,
  initExperiments,
  shouldEnableTimeoutLogs,
  shouldEnableSeoPanelChanges,
  shouldEnableMigration,
  shouldInstallMembersAreaV2,
  shouldInstallMembersAreaV3,
  shouldEnableMembersAreaContextCheck,
  shouldEnableV2SilentInstall,
  shouldNotWaitNavigation,
  shouldRemoveUpdatingBoBControllerAndNavigation,
  shouldUseCustomizeMemberPageCTA,
  shouldEnableNotificationsIconFixer,
  shouldAddNotificationsIcon,
  shouldEnableMenusDataFixer,
  shouldEnableV2TpaPageLinksDataFixer,
  shouldEnableV3TpaPageLinksDataFixer,
  shouldShowPageRedirectNote,
  shouldEnableV2PageInfoPanelCustomPage,
  shouldEnableV3ReAddPageWorkaround,
  shouldEnableMyAccountParallelInstall,
  shouldEnableFollowersAsLightbox,
  shouldShowOnboardingModal,
  shouldEnableUnifiedManager,
  shouldUseNewLoginBarDeleteModal,
  shouldEnableUninstallMASubApps,
  shouldEnableVerticalProfileInstallFixer,
  shouldEnableExtendedUninstallMASubApps,
};
