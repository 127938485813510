import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, MembersAreaAppId } from '@wix/members-area-app-definitions';
import { createInstance } from 'i18next';
import { getAppDefinitions } from '@wix/members-area-app-definitions/get-app-definitions';

export const getDefinitions = async (
  editorSDK: EditorSDK,
  membersAreaAppIds: MembersAreaAppId[],
): Promise<IntegrationApplication[]> => {
  return getAppDefinitions({
    editorSDK,
    applications: membersAreaAppIds,
    // @ts-expect-error - versions mismatch
    i18next: createInstance(),
  });
};
