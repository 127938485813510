import type { EditorSDK } from '@wix/platform-editor-sdk';
import { getAppDefIdFromWidgetId, WidgetId } from '@wix/members-area-app-definitions';
import { getAppDefinition } from '@wix/members-area-app-definitions/get-app-definitions';

export const getAppDefinitionByWidgetId = async (editorSDK: EditorSDK, widgetId: WidgetId) => {
  const appDefinitionId = getAppDefIdFromWidgetId(widgetId);

  return getAppDefinition({
    editorSDK,
    application: { widgetId, appDefinitionId },
  });
};
