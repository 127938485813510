import type { AppActionClicked as AppActionClickedEvent } from '@wix/platform-editor-sdk';
import { EventType } from '@wix/platform-editor-sdk';

import { MembersAreaEvent, MembersAreaOnEventFn } from '../../types';

import { openRemovePagePanel, openUninstallPanel } from '../../../wrappers/platformPanels';
import {
  openAddPagesPanel,
  openMemberPrivacySettingsDashboard,
  openUnifiedManagerPanel,
} from '../../../wrappers/panels';
import { publishSettingsForMembersAreaApps } from '../../../services/members-area';
import { handleLoginBarAdded } from '../services/login-bar';
import { handleAppManagerEvent } from './apps-manager';
import { handleCreateBlankPageEvent } from './custom-pages';

export const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const { eventType, eventPayload } = event;

  switch (eventType) {
    // Platform Events
    case EventType.appActionClicked: {
      return handleAppManagerEvent(editorSDK, event as AppActionClickedEvent);
    }
    case EventType.widgetAdded: {
      return handleLoginBarAdded(editorSDK, eventPayload);
    }
    case EventType.siteWasPublished: {
      return publishSettingsForMembersAreaApps(editorSDK);
    }
    // Members Area Events
    case MembersAreaEvent.CreateBlankPage:
      return handleCreateBlankPageEvent(editorSDK);
    case MembersAreaEvent.MemberPrivacyDashboard: {
      return openMemberPrivacySettingsDashboard(editorSDK);
    }
    case MembersAreaEvent.Uninstall: {
      return openUninstallPanel(editorSDK);
    }
    case MembersAreaEvent.AddPages: {
      return openAddPagesPanel({ editorSDK, eventPayload });
    }
    case MembersAreaEvent.OpenUnifiedManager: {
      return openUnifiedManagerPanel({ editorSDK, eventPayload: { initialTab: 'pages' } });
    }
    case MembersAreaEvent.RemovePage: {
      return openRemovePagePanel(editorSDK, eventPayload.pageRef);
    }
  }
};
