import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';

import { monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { MENU_IDS } from '../../constants';
import { getPageData, rename as renamePage, setPagesState } from '../../wrappers/pages';
import { addMenuItem } from '../../wrappers/menus';
import { isCurrentLanguageOriginal } from '../../wrappers/language';
import { openSeoRedirectDashboard } from '../../wrappers/panels';
import { shouldShowPageRedirectNote } from '../../../utils/experiments';

import type { MembersAreaPrivateApi } from '../types';
import { getIsMigration } from '../../services/applicationState';
import { uninstallMembersArea } from './services/uninstall';
import { getPublicApplications, refreshMemberPage } from './services/members-area-page';
import { createBlankPrivatePage, removePage, updatePageUriSEO } from './services/pages';
import { getUnifiedManagerPanelData } from './services/panels';
import { deleteApps } from './integration';

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  return {
    hasSocialPages: () => {
      const action = async () => {
        const installedPublicApps = await getPublicApplications(editorSDK);
        return installedPublicApps.length > 0;
      };

      return monitoredTransaction('private-api.v2.hasSocialPages', action);
    },
    uninstall: () => {
      return monitoredTransaction('v2.uninstall', () => uninstallMembersArea(editorSDK));
    },
    removePage: (pageData) => {
      const action = async () => {
        if (pageData.id) {
          return removePage(editorSDK, { pageId: pageData.id });
        }
        throw new Error('Page ID not found');
      };
      return monitoredTransaction('private-api.v2.removePage', action);
    },
    createPrivateMemberPage: () => {
      const action = async () => {
        const { pageTitle, pageRef } = await toMonitored(
          'private-api.v2.createPrivateMemberPage.createBlankPrivatePage',
          () => createBlankPrivatePage(editorSDK),
        );

        const { id: pageId } = await toMonitored('private-api.v2.createPrivateMemberPage.getPageData', () =>
          getPageData({ editorSDK, pageRef }),
        );

        await toMonitored('private-api.v2.createPrivateMemberPage.addMenuItem', () =>
          addMenuItem({
            editorSDK,
            menuId: MENU_IDS.LOGIN_MENU_ID,
            menuItem: {
              label: pageTitle,
              link: {
                pageId: `#${pageId!}`,
                target: '_self',
                type: 'PageLink',
              },
            },
          }),
        );

        await toMonitored('private-api.v2.createPrivateMemberPage.setPagesState', () =>
          setPagesState({ editorSDK, state: { custom: [pageRef] } }),
        );

        return { pageRef, pageTitle };
      };

      return monitoredTransaction('private-api.v2.createPrivateMemberPage', action);
    },
    shouldAllowUpdatingPagePrefix: () => {
      return isCurrentLanguageOriginal(editorSDK.language);
    },
    openSeoRedirectDashboard: () => {
      return openSeoRedirectDashboard(editorSDK);
    },
    shouldShowPageRedirectNote: () => {
      return shouldShowPageRedirectNote();
    },
    renamePage: (title, pageRef) => {
      const action = async () => renamePage({ editorSDK, pageRef, title });
      return monitoredTransaction('private-api.v2.renamePage', action);
    },
    updatePageData: ({ pageRef, pageData }) => {
      const action = async () => updatePageUriSEO(editorSDK, pageRef, pageData as Pick<PageData, 'pageUriSEO'>);
      return monitoredTransaction('private-api.v2.updatePageData', action);
    },
    refreshMembersBlocksPlatform: async () => {
      const action = async () => refreshMemberPage(editorSDK);
      return monitoredTransaction('private-api.v2.refreshMembersBlocksPlatform', action);
    },
    deleteApps: async (definitions) => {
      const action = async () => deleteApps(editorSDK, definitions);
      return monitoredTransaction('private-api.v2.deleteApps', action);
    },
    getUnifiedManagerPanelData: async () => {
      const action = async () => getUnifiedManagerPanelData(editorSDK);
      return monitoredTransaction('private-api.v2.getUnifiedManagerPanelData', action);
    },
    /** @deprecated */
    getAllRouters: () => {
      throw new Error('[MAV2] PrivateAPI.getAllRouters is not implemented');
    },
    /** @deprecated */
    getRouterDataByPage: (pageRef) => {
      throw new Error('[MAV2] PrivateAPI.getRouterDataByPage is not implemented');
    },
    /** @deprecated */
    updatePageDataInRouter: (pageRef, dataFieldToUpdate, updatedData) => {
      throw new Error('[MAV2] PrivateAPI.updatePageDataInRouter is not implemented');
    },
    /** @deprecated */
    saveInnerRoute: (newInnerRoute, pageRef, pageRouterData) => {
      throw new Error('[MAV2] PrivateAPI.saveInnerRoute is not implemented');
    },
    /** @deprecated */
    savePasswordPermissions: (pageRef, privacy, plainPassword) => {
      throw new Error('[MAV2] PrivateAPI.savePasswordPermissions is not implemented');
    },
    /** @deprecated - only for templates migration to V2 */
    getIsMigrating: () => {
      return Promise.resolve(getIsMigration());
    },
  };
};
