import type { EditorSDK, PageRef } from '@wix/platform-editor-sdk';
import type { IntegrationApplication } from '@wix/members-area-integration-kit';

import type { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';

import { retryPromise } from '../../../../utils/promises';
import { remove as removePage } from '../../../wrappers/pages';
import { getPageRefByTPAPageId } from '../../../wrappers/tpa';
import { getById as getComponentById, getComponentPage } from '../../../wrappers/components';
import { getPageByIntegrationApp, updatePageWithManagingAppDefId } from '../../../services/pages';
import { uninstallDeletableApps } from '../../../services/uninstall-apps';
import { shouldEnableExtendedUninstallMASubApps } from '../../../../utils/experiments';
import { removeApplicationsFromSlots } from '../services/members-blocks-platform';
import { getAppsToDeleteWithVertical } from './get-apps';
import { groupDefinitionsByMethod } from './definitions';
import { registerAlwaysAvailableApps } from './register-apps';

export const deleteStandalonePrivatePage = async (
  editorSDK: EditorSDK,
  pageId: string,
  pageToNavigateAfterRemove?: PageRef,
) => {
  const pageRef = await getPageRefByTPAPageId(editorSDK, pageId);
  if (pageRef) {
    return removePage({
      editorSDK,
      pageRef,
      pageToNavigateAfterRemove,
      shouldShowEditorRemovePanel: false,
    });
  }
};

const deleteStandalonePrivatePages = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  return Promise.all(definitions.map(({ pageId }) => deleteStandalonePrivatePage(editorSDK, pageId)));
};

const getPageHasManagingAppDefId = async (editorSDK: EditorSDK, definition: IntegrationApplicationWithoutWidgetId) => {
  const page = await getPageByIntegrationApp({ editorSDK, app: definition });
  return !!page?.managingAppDefId;
};

const deleteSitePage = async (editorSDK: EditorSDK, definition: IntegrationApplicationWithoutWidgetId) => {
  const page = await getPageByIntegrationApp({ editorSDK, app: definition });
  if (!page) {
    return;
  }

  const componentRef = await getComponentById({ editorSDK, id: page.id! });
  if (!componentRef) {
    return;
  }

  const pageRef = await getComponentPage(editorSDK, componentRef);
  if (!pageRef) {
    return;
  }

  if (!page.managingAppDefId) {
    const retryOptions = {
      delayMs: 100,
      delayConditionCheckMs: 100,
      message: `Failed to to update page with managing app def id ${definition.appDefinitionId}`,
      maxTries: 4,
      condition: () => getPageHasManagingAppDefId(editorSDK, definition),
    };

    await retryPromise(() => updatePageWithManagingAppDefId({ editorSDK, pageRef }), retryOptions);
  }

  await removePage({ editorSDK, pageRef, shouldShowEditorRemovePanel: false });
};

const deleteSitePages = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithoutWidgetId[]) => {
  return Promise.all(definitions.map((definition) => deleteSitePage(editorSDK, definition)));
};

export const deleteApps = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const groupedDefinitions = groupDefinitionsByMethod(definitions);
  const { standalonePrivatePages, profileWidgetPlugins, settingsWidgetPlugins, siteApps } = groupedDefinitions;
  const widgetPluginsToDelete = [...profileWidgetPlugins, ...settingsWidgetPlugins].map(({ widgetId }) => widgetId);

  await Promise.all([
    widgetPluginsToDelete.length > 0
      ? removeApplicationsFromSlots(editorSDK, widgetPluginsToDelete)
      : Promise.resolve(),
    deleteStandalonePrivatePages(editorSDK, standalonePrivatePages),
    deleteSitePages(editorSDK, siteApps),
  ]);

  if (await shouldEnableExtendedUninstallMASubApps()) {
    await uninstallDeletableApps(editorSDK, definitions);
  }

  await registerAlwaysAvailableApps(editorSDK);
};

export const deleteVerticalApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const appsToDeleteWithVertical = await getAppsToDeleteWithVertical(editorSDK, verticalAppDefId);
  return deleteApps(editorSDK, appsToDeleteWithVertical);
};
